.error label {
    color: #f44336 !important;
}

.error input {
    color: #f44336 !important;
}

.error fieldset {
    border-color: #f44336 !important;
}