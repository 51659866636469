.user-tab {
  .MuiTableContainer-root {
    max-height: 600px;
    overflow-y: auto;
  }

  .email {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
